import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../../components/Layout"
import Seo from "../../components/SEO"

const FacilityTemplate = ({ data }) => {
  const {
    title,
    amenities,
    description: { raw },
    featuredImage,
    images,
  } = data.contentfulFacility

  const descriptionText = JSON.parse(raw).content[0].content[0].value

  const pathToFeaturedImage = getImage(featuredImage)

  const pathsToImages = [
    getImage(images[0]),
    getImage(images[1]),
    getImage(images[2]),
  ]

  return (
    <Layout className="section-stay">
      <Seo title={title} />
      <div className="container">
        <h2 className="section-stay__title">{title}</h2>
      </div>
      <section className="section-stay__description">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 d-flex flex-column justify-content-center">
              <p className="section-stay__description__text">
                {descriptionText}
              </p>
            </div>
            <div className="col-12 col-lg-8">
              <GatsbyImage
                image={pathToFeaturedImage}
                alt="Featured Image"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-stay__gallery">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <GatsbyImage
                image={pathsToImages[0]}
                alt="Placeholder"
                className="w-100"
              />
            </div>
            <div className="col-12 col-md-4">
              <GatsbyImage
                image={pathsToImages[1]}
                alt="Placeholder"
                className="w-100"
              />
            </div>
            <div className="col-12 col-md-4">
              <GatsbyImage
                image={pathsToImages[2]}
                alt="Placeholder"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-stay__amenities">
        <div className="container">
          <div className="row">
            <h3 className="section-stay__amenities__title">Amenities</h3>
          </div>
          <div className="row section-stay__amenities__list">
            <div className="col-12 col-lg-6">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">{amenities[0]}</li>
                <li className="list-group-item">{amenities[1]}</li>

                <li className="list-group-item">{amenities[2]}</li>
              </ul>
            </div>
            <div className="col-12 col-lg-6">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">{amenities[3]}</li>
                <li className="list-group-item">{amenities[4]}</li>
                <li className="list-group-item">{amenities[5]}</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <p className="section-stay__amenities__text">
              Think of something that is not listed above? Feel free to send us
              a request and we will make sure to accommodate you!
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query getFacility($title: String) {
    contentfulFacility(title: { eq: $title }) {
      title
      amenities
      description {
        raw
      }
      featuredImage {
        gatsbyImageData
      }
      images {
        gatsbyImageData
      }
    }
  }
`

export default FacilityTemplate
